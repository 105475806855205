import { NextPage } from 'next'
import { AuthenticationModal, MagicConnectUserContext } from '@fuel/auth'
import { useContext, useEffect } from 'react'
import { useRouter } from 'next/router'
import { routes } from '../constants/routes'
import { useLogout } from '@fuel/auth'
import styled from '@emotion/styled'
import { Center, Spinner } from '@chakra-ui/react'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

const Index: NextPage = () => {
  const { userDetails } = useContext<any>(MagicConnectUserContext)
  const router = useRouter()
  const { logout } = useLogout()

  useEffect(() => {
    if (typeof window !== 'undefined' && (window as any).Intercom) {
      ;(window as any).Intercom('update', {
        hide_default_launcher: true,
      })
    }
  }, [])

  useEffect(() => {
    const handleFetchArtist = async () => {
      if (userDetails?.wallet_public_address) {
        if (userDetails.type === 'User') {
          router.push(routes.NO_CREATOR)
        } else {
          router.push(routes.COLLECTIONS)
        }
      }
    }

    handleFetchArtist()
  }, [userDetails, logout, router])

  const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  `

  if (userDetails?.wallet_public_address) {
    return (
      <Center minW="100vh" position="absolute" top="50%" w="full">
        <Spinner />
      </Center>
    )
  }

  return (
    <StyledContainer>
      <AuthenticationModal alwaysOpened={true} showButton={false} />
    </StyledContainer>
  )
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common', 'auth'])),
    },
  }
}

export default Index
